import(/* webpackMode: "eager", webpackExports: ["AidnTheme"] */ "__barrel_optimize__?names=AidnTheme!=!/vercel/path1/node_modules/@aidnas/design-system/dist/esm/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/brand/app/[site]/alert-banner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/brand/app/[site]/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/brand/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/brand/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/brand/components/Layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/brand/components/Sidebar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tracker"] */ "/vercel/path1/brand/components/Tracker/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path1/brand/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@aidnas/design-system-bandaid/lib/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/highlight.js/styles/github.css");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"app/[site]/layout.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"variable\":\"--font-mono\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"robotoMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/google/target.css?{\"path\":\"app/[site]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-inter\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
