"use client";

import { vercelStegaClean } from "@vercel/stega";
import { useParams, usePathname } from "next/navigation";
import { createDataAttribute } from "next-sanity";

import NextButtonLink from "../NextButtonLink";

import styles from "./Styles.module.scss";

import Link from "@/components/Link";
import Logo from "@/components/Logo";
import BEMHelper from "@/lib/bem";
import { getFirstPageInSection } from "@/lib/sections";
import { SiteQueryResult } from "@/types/sanity";

const bem = BEMHelper(styles);

type Props = Pick<NonNullable<SiteQueryResult>, "headerNav" | "sections" | "title" | "_id">;

export default function Header(props: Props) {
  const params = useParams();
  const pathname = usePathname();

  const attr = createDataAttribute({
    id: props._id,
    type: "site",
  });

  const { headerNav, sections, title } = props || {};

  return (
    <div {...bem("")}>
      <Logo text={title || ""} />

      {pathname === "/login" ? (
        <div />
      ) : (
        <nav {...bem("sections")}>
          {sections?.map((section) => {
            const firstPage = getFirstPageInSection(section);

            if (!firstPage) {
              return (
                <div
                  key={section._key}
                  {...bem("section", { active: section?.slug?.current === params.section })}
                >
                  {section.title}
                </div>
              );
            }

            return (
              <Link
                key={section._key}
                href={`/${section?.slug?.current}${firstPage ? `/${firstPage?.slug?.current}` : ""}`}
                {...bem("section", { active: section?.slug?.current === params.section })}
              >
                {section.title}
              </Link>
            );
          })}
        </nav>
      )}

      <div {...bem("links")}>
        {headerNav?.map((item) => {
          const label = vercelStegaClean(item.label);
          const href = item.href || `/${item?.reference?.section}/${item?.reference.slug}`;

          return (
            <NextButtonLink
              key={item._key}
              href={href}
              {...(item.href ? { rel: "noreferrer noopener", target: "_blank" } : {})}
              data-sanity={attr(["headerNav", { _key: item._key }])}
            >
              {label || ""}
            </NextButtonLink>
          );
        })}
      </div>
    </div>
  );
}
