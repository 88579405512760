"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { v1 as uuid } from "uuid";

type EventData = {
  "Storefront: User Viewed Page": {
    /**
     * The URL of the page that was viewed.
     */
    url: string;
  };
};

/**
 * Track an event with Amplitude. It will automatically add the user ID to the event.
 *
 * Note: When introducing a new type of event, make sure to add it to the
 * `EventData` type.
 *
 * This function is available on the client only.
 *
 * @param event The event name.
 * @param data The event data.
 */
export const track = (event: keyof EventData, data: EventData[typeof event]) => {
  if (typeof window === "undefined") return;

  const userId = localStorage.getItem("user_id") ?? uuid();
  localStorage.setItem("user_id", userId);

  // Post to /api/track/
  fetch("/api/track/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      event,
      data,
      user_id: userId,
    }),
  });
};

/**
 * This component tracks page views with Amplitude.
 */
export const Tracker = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (typeof window !== "undefined") {
      track("Storefront: User Viewed Page", {
        url: pathname,
      });
    }
  }, [pathname]);

  return null;
};
