"use client";

import { Tag } from "@aidnas/design-system-bandaid";
import { vercelStegaClean } from "@vercel/stega";
import { useParams } from "next/navigation";

import styles from "./Styles.module.scss";

import Link from "@/components/Link";
import BEMHelper from "@/lib/bem";
import { cleanSections } from "@/lib/sections";
import { SiteQueryResult } from "@/types/sanity";

type HeaderProps = {
  sections?: NonNullable<SiteQueryResult>["sections"];
};

const bem = BEMHelper(styles);

function Label({ label }: { label: string | null }) {
  const cleanLabel = vercelStegaClean(label);

  if (!cleanLabel) {
    return null;
  }

  return (
    <Tag bordered color={cleanLabel === "updated" ? "yellow" : "blue"} {...bem("tag")}>
      {cleanLabel.toUpperCase()}
    </Tag>
  );
}

export default function Sidebar({ sections }: HeaderProps) {
  const cleanedSections = cleanSections(sections);

  const { section, slug } = useParams<{ site: string; section?: string; slug?: string }>();
  const activeSection = cleanedSections?.find((s) => s.slug?.current === section);

  return (
    <nav {...bem("")}>
      <ul {...bem("sections")}>
        {activeSection?.sidebar?.map((sidebarSection) => {
          if (sidebarSection.hidden) return null;

          return (
            <li key={sidebarSection._key}>
              <div {...bem("section-title")}>{sidebarSection.title}</div>

              <ul {...bem("section-pages")}>
                {sidebarSection?.docPages?.map((docPage) => (
                  <li key={docPage._id}>
                    <Link
                      href={`/${activeSection?.slug?.current}/${docPage.slug?.current}`}
                      {...bem("page", { active: docPage.slug?.current === slug })}
                    >
                      <span {...bem("title")}>{docPage.title}</span> <Label label={docPage.label} />
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>

      <div></div>
    </nav>
  );
}
