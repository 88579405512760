import React from "react";
import { ButtonLink } from "@aidnas/design-system";

import Link from "@/components/Link";

type NextButtonLinkProps = {
  href: string;
  children: string;
};

// `onClick`, `href`, and `ref` need to be passed to the DOM element
// for proper handling
export default function NextButtonLink({
  children,
  "data-sanity": sanityData,
  href,
  ...props
}: NextButtonLinkProps & { "data-sanity"?: string }) {
  return (
    <>
      <Link href={href} legacyBehavior passHref>
        <>
          <ButtonLink {...props} data-sanity={sanityData} href={href}>
            {children}
          </ButtonLink>
        </>
      </Link>
    </>
  );
}
