"use client";
import { Flex } from "@aidnas/design-system";
import { Divider } from "@aidnas/design-system-bandaid";
import Link from "next/link";
import { usePathname } from "next/navigation";

import packageJson from "../../../package.json";
import PortableText from "../PortableText";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import { SiteQueryResult } from "@/types/sanity";

const dsPackageVersion = packageJson.dependencies["@aidnas/design-system"].replace("^", "");

type Props = Pick<NonNullable<SiteQueryResult>, "footer" | "_id"> & { signedIn: boolean };

const bem = BEMHelper(styles);

export default function Footer(props: Props) {
  const pathname = usePathname();

  const { footer } = props || {};

  if (
    !footer ||
    !props.signedIn ||
    pathname.match(new RegExp(`^(?:/${props._id})?(?:/(?:login)?)?$`))
  ) {
    return null;
  }

  return (
    <footer {...bem("")}>
      <Divider />
      <Flex gap="spacing-100" horizontal justify="center">
        <PortableText value={footer} /> –{" "}
        <Link href="https://github.com/AidnAS/design-system/releases" target="_blank">
          version {dsPackageVersion}
        </Link>
      </Flex>
    </footer>
  );
}
