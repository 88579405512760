import styles from "./Styles.module.scss";

import Link from "@/components/Link";
import BEMHelper from "@/lib/bem";

type LogoProps = {
  text: string;
};

const bem = BEMHelper(styles);

export default function Logo({ text }: LogoProps) {
  return (
    <Link href="/" {...bem("")}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="#1C1221" height="24" rx="6" width="24" />
        <path
          d="M13.2808 16.0672C12.6299 17.4743 11.121 18.6989 9.3257 18.6989C7.53041 18.6989 6.09961 17.5265 6.09961 15.6499C6.09961 12.966 9.19553 11.8707 12.2915 11.8707C12.6299 11.8707 12.9944 11.8968 13.3317 11.9228C13.3838 11.2187 13.4098 10.5157 13.4098 10.2549C13.4359 8.50872 12.2394 7.70137 10.5222 7.70137C9.20421 7.70137 7.81788 8.20447 6.91427 8.8586C7.51306 6.60607 9.58604 5.2 12.0571 5.2C14.5282 5.2 16.8182 6.52892 16.8182 9.68225C16.8182 10.5678 16.6359 11.6892 16.6359 13.5136C16.6359 16.0672 17.0265 16.693 18.8738 17.3189C18.1709 18.1263 16.714 18.7 15.6477 18.7C14.1128 18.7 13.4359 17.5276 13.2797 16.0682L13.2808 16.0672ZM13.2808 13.2528C13.2808 12.966 13.3068 12.4966 13.3328 12.0543C10.8097 12.4194 9.50794 13.305 9.50794 14.6861C9.50794 16.0672 10.6263 16.4062 11.6677 16.3019C12.0843 16.3019 12.7861 16.1193 13.2808 15.8846C13.2547 15.7021 13.2547 15.4934 13.2547 15.2848C13.2547 14.8415 13.2808 13.4082 13.2808 13.2518V13.2528Z"
          fill="#FEF5F2"
        />
      </svg>
      {text}
    </Link>
  );
}
