"use client";

import { useSyncExternalStore, useTransition } from "react";
import { useRouter } from "next/navigation";

import { disableDraftMode } from "./actions";

const emptySubscribe = () => () => {};

export default function AlertBanner() {
  const router = useRouter();
  const [pending, startTransition] = useTransition();

  const shouldShow = useSyncExternalStore(
    emptySubscribe,
    () => window.top === window,
    () => false
  );

  const handleBackToPublished = () =>
    startTransition(() =>
      disableDraftMode().then(() => {
        router.refresh();
      })
    );

  if (!shouldShow) return null;

  return (
    <div
      className={`${
        pending ? "animate-pulse" : ""
      } fixed top-0 left-0 z-50 w-full border-b bg-white/95 text-black backdrop-blur`}
    >
      <div className="py-2 text-center text-sm">
        {pending ? (
          "Disabling draft mode..."
        ) : (
          <>
            {"Previewing drafts. "}

            <button
              className="hover:text-cyan underline transition-colors duration-200"
              onClick={handleBackToPublished}
              type="button"
            >
              Back to published
            </button>
          </>
        )}
      </div>
    </div>
  );
}
