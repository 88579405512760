"use client";

import { ReactElement } from "react";
import { usePathname } from "next/navigation";

import styles from "./Styles.module.scss";

import { useSitePrefix } from "@/hooks/sitePrefix";
import BEMHelper from "@/lib/bem";

type LayoutProps = {
  children: ReactElement;
  sidebar?: ReactElement;
  header?: ReactElement;
};

const bem = BEMHelper(styles);

export default function Layout({ children, header, sidebar }: LayoutProps) {
  const pathname = usePathname();
  const siteprefix = useSitePrefix();

  // We show the sidebar if the pathname is not / or /login
  const showSidebar = sidebar && !pathname.match(new RegExp(`^(?:/${siteprefix})?(?:/login)?/?$`));

  return (
    <div {...bem("")}>
      <div {...bem("header")}>{header}</div>
      <div {...bem("body", { center: !showSidebar })}>
        {showSidebar ? <div {...bem("sidebar")}>{sidebar}</div> : null}

        <main {...bem("main")}>
          <div {...bem("content")}>{children}</div>
        </main>
      </div>
    </div>
  );
}
